<template>
    <IFrame :src="url"/>
</template>
<script setup lang="ts">
    import { OIframe as IFrame} from 'o365-ui-components';
    import { computed } from "vue";
    import Url from "o365.modules.url.ts";

    interface Props {
        fileName: string,
        primKey: string,
        viewName: string,
        id: number
    };

    const props = defineProps<Props>();
    const url = computed(() => { return `/foxit9?params=${Url.decodObject(props)}`})
    //const url = ref(`/foxit9?param=${Url.decodObject(props)}`); 
</script>